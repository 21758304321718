import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d0ae2ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-top" }
const _hoisted_2 = {
  class: "flex align-center justify-between",
  style: {"padding":"10px"}
}
const _hoisted_3 = { class: "text-bold text-sm" }
const _hoisted_4 = { class: "text-xxl" }
const _hoisted_5 = {
  class: "flex flex-direction justify-between",
  style: {"margin-top":"-20px"}
}
const _hoisted_6 = { class: "flex-sub flex flex-direction justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createBlock(_component_el_skeleton, {
    loading: _ctx.loading,
    animated: ""
  }, {
    template: _withCtx(() => [
      _createVNode(_component_el_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_skeleton_item, {
            variant: "h3",
            style: {"width":"50%"}
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_skeleton_item, { variant: "text" }),
            _createVNode(_component_el_skeleton_item, {
              variant: "text",
              style: {"width":"50%"},
              class: "margin-top"
            }),
            _createVNode(_component_el_skeleton_item, {
              variant: "text",
              class: "margin-top"
            }),
            _createVNode(_component_el_skeleton_item, {
              variant: "text",
              style: {"width":"50%"},
              class: "margin-top"
            })
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_card, {
        "body-style": { padding: '20px' },
        shadow: "never"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dataModel.title), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dataModel.data), 1 /* TEXT */)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "extra", {
                extra: _ctx.dataModel.extra
              }, undefined, true)
            ])
          ])
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"]))
}