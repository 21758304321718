
import DataItem from "./components/DataItem.vue";
// import OrderChart from "./components/chart/OrderChart.vue";
import SalesChart from "./components/chart/SalesChart.vue";
import StudentChart from "./components/chart/StudentChart.vue";
import EnrollmentChannelsChart from "./components/chart/EnrollmentChannelsChart.vue";
import FullYearSalesChart from "./components/chart/FullYearSalesChart.vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import { useLayoutStore } from "@/layouts/hooks";
export default defineComponent({
  name: "Home",
  components: {
    DataItem,
    // OrderChart,
    SalesChart,
    StudentChart,
    EnrollmentChannelsChart,
    FullYearSalesChart,
  },
  setup() {
    const layoutStore = useLayoutStore();
    // const mOrderChart = ref<InstanceType<typeof OrderChart>>();
    const salesChart = ref<InstanceType<typeof SalesChart>>();
    const enrollmentChannelsChart =
      ref<InstanceType<typeof EnrollmentChannelsChart>>();
    const studentChart = ref<InstanceType<typeof StudentChart>>();
    const fullYearSalesChart = ref<InstanceType<typeof FullYearSalesChart>>();
    const onResize = () => {
      setTimeout(() => {
        // mOrderChart.value?.updateChart();
        salesChart.value?.updateChart();
        enrollmentChannelsChart.value?.updateChart();
        studentChart.value?.updateChart();
        fullYearSalesChart.value?.updateChart();
      }, 500);
    };
    const collapse = computed(() => {
      return layoutStore.state.isCollapse;
    });
    watch(collapse, (newVal: boolean) => {
      onResize();
    });
    return {
      collapse,
      // mOrderChart,
      salesChart,
      enrollmentChannelsChart,
      studentChart,
      fullYearSalesChart,
      dataList: [
        {
          title: "迭代中",
          data: "0",
          extra: {
            data: 0,
            data1: 0,
          },
        },
        {
          title: "迭代中",
          data: "0",
          bottomTitle: "总用户量",
          totalSum: "200万+",
          extra: {
            data: 0,
            data1: 0,
          },
        },
        {
          title: "迭代中",
          data: "0",
          bottomTitle: "总访问量",
          totalSum: "100万+",
          extra: {
            data: 0,
            data1: 0,
          },
        },
        {
          title: "迭代中",
          data: "0",
          bottomTitle: "总用户量",
          totalSum: "200万+",
          extra: {
            data: 0,
            data1: 0,
          },
        },

      ],
    };
  },
});
